.tabs {
    display: flex center;
}

.about {
    display: flex center;
}

.img {
    width: 25rem;
    height: auto;
}