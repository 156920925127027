body {
    background: linear-gradient(360deg, #987654, #DF6C4F)fixed no-repeat ;

}

.container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .text {
    margin-right: 10px;
  }

  .image {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 600px) {
    .container {
      flex-direction: column;
      align-items: flex-start;
    }
  }