body {
  margin: 0;
  font-family: "Helvetica", Arial, sans-serif;
    font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(360deg, #987654, #DF6C4F)fixed no-repeat ;


}

.form-control {
  width: 50%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
